<template>
  <ForgotPasswordPage />
</template>

<script>
import ForgotPasswordPage from "@/components/layout/auth/ForgotPasswordPage";
export default {
  name: "ForgotPassword",
  components: { ForgotPasswordPage }
};
</script>

<style scoped></style>
