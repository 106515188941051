<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-container>
          <validation-observer ref="observer">
            <form @submit.prevent="submit">
              <v-row align="center" justify="center">
                <v-col cols="auto">
                  <v-img
                    width="150"
                    :src="require('@/assets/images/cp-app-logo-alt.svg')"
                  />
                </v-col>

                <v-col cols="12">
                  <div class="main--text lighten-2 text-heading-1 mb-2">
                    {{ $t("password_reset_message") }}
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <input-email
                      v-model="email"
                      :label="$t('enter_your_email')"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    color="primary"
                    block
                    @click="submit"
                    :disabled="getApiProcessingStatus"
                    :loading="getApiProcessingStatus"
                  >
                    {{ $t("request_password_reset") }}
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    color="primary"
                    block
                    to="/login"
                    :disabled="getApiProcessingStatus"
                    :loading="getApiProcessingStatus"
                  >
                    {{ $t("title_login") }}
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import InputEmail from "@/components/ui/InputEmail";
import { mapGetters } from "vuex";
export default {
  name: "ForgotPasswordPage",
  components: { InputEmail },
  data() {
    return {
      email: ""
    };
  },
  computed: {
    ...mapGetters(["getApiProcessingStatus"])
  },
  methods: {
    submit() {
      let email = this.email;
      let reset_url = `${process.env.VUE_APP_DEV_URL}reset-password/`;

      this.$store
        .dispatch("AUTH_FORGOT", { email, reset_url })
        .then(
          response => {
            console.log(response);
            if (response.status === 200) {
              this.$router.push("/");
            }
          },
          error => {
            console.error(error);
          }
        )
        .catch(err => console.log(err));
    }
  }
};
</script>

<style scoped></style>
